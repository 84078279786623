<template>
  <div class="base-card">
    <div class="card-header" :class="{ 'spinner': props.spinnerOn }">
      <div class="card-name">{{ props.cardName }}</div>
      <div class="card-icon">
        <slot name="icon">
          <span class="material-symbols-outlined">{{ props.icon }}</span>
        </slot>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  cardName: String,
  icon: String,
  spinnerOn: Boolean,
})

</script>

<style lang="scss" scoped>
.base-card {
  border-radius: 7px;
  box-shadow: 0 0 3px #b9b9b9;
  background-color: var(--White);
  overflow: hidden;
  position: relative;
}

.card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 1.4rem;
  color: var(--Grey600);
  --angle: 90deg;
  border-bottom: 1px solid;
  border-image: conic-gradient(from var(--angle), var(--MarketingMagenta), var(--BlendedOrange), var(--MarketingOrange), var(--BlendedOrange), var(--MarketingMagenta)) 1;
  transition: all linear 300ms;

  &>.card-icon {
    width: 1.4rem;
    cursor: pointer;
    padding-top: 3px;
  }

}
</style>

<style type="text/css" scoped>
.spinner {
  --angle: 360deg;
  border-bottom: 1vmin solid;
  border-image: conic-gradient(from var(--angle), #e901be, #f46d4e, #ffaa0f, #f46d4e, #e901be) 1;
  animation: 1s rotate linear infinite;
}

@keyframes rotate {
  to {
    --angle: 0deg;
  }
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: true;
}
</style>
