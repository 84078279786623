<template>
  <div>
    <BaseCard :card-name="tr('Tumor Board Sessions')" icon="add" :spinnerOn="filingSpinner">
      <div class="base-tb-container" :class="{ 'spinner-fade': filingSpinner }">
        <div class="tb-tab-container">
          <div class="tb-tab" :class="{ 'tb-tab-active': tabHighActive }" id="tb-all-tab" @click="tabTBClick('High')">
            {{ tr('Highlights') }}
          </div>
          <div class="tb-tab" :class="{ 'tb-tab-active': tabPrevActive }" id="tb-gen-tab" @click="tabTBClick('Prev')">
            {{ tr('Previous Sessions') }}</div>
        </div>
        <div class="tb-list-container">
          <div class="tb-container" :class="{ 'tb-container-active': tabHighActive }" id="tb-all-list">Highlights
          </div>
          <div class="tb-container" :class="{ 'tb-container-active': tabPrevActive }" id="tb-gen-list">Previous
          </div>
        </div>
      </div>
    </BaseCard>

  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import BaseCard from '../BaseCard/BaseCard.vue'
//import { fetchWithTimeout } from '@/common/shared'
//import getEnv from '@/utils/env'
//import { format } from 'date-fns'
//import { useStore } from 'vuex'
//import { calculateLastAccessDate } from '@/common/shared.js'

//const store = useStore()
const tr = (x) => x // inject('tr')
const tabHighActive = ref(true)
const tabPrevActive = ref(false)

const clearTBTabs = () => {
  tabHighActive.value = false
  tabPrevActive.value = false
}

const tabLookup = { 'High': tabHighActive, 'Prev': tabPrevActive }

const tabTBClick = (tab) => {
  clearTBTabs()
  tabLookup[tab].value = true
}

//const patientId = '0x444531'  // TODO

const filingSpinner = ref(false)

onMounted(async () => {
  // TODO: pull TB data for patient
})

</script>

<style lang="scss" scoped>
.base-tb-container {
  overflow-y: auto;
  height: 300px;
  margin-bottom: 30px;
  transition: opacity linear 1000ms;
}

.spinner-fade {
  opacity: 0.2 !important;
  transition: opacity linear 300ms;
}

.tb-tab-container {
  color: var(--Grey500);
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
}

.tb-tab {
  display: flex;
  flex-grow: 1;
  background-color: var(--Grey100);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-left: 1px solid var(--Grey300);
  cursor: pointer;
  transition: all linear 200ms;

  &:hover {
    color: var(--Black);
  }

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }

}

.tb-tab-active {
  background-color: var(--White);
  border-bottom: none;
  color: var(--Grey600);
  cursor: default;
}

.tb-container {
  display: none;
  padding: 15px;
}

.tb-container-active {
  display: flex;
}

.tb-container {
  overflow: auto;
}
</style>

