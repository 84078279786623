<template>
  <NavBar></NavBar>
  <BaseDrawer :is-open="isDrawerOpen" :speed="200" @close="closeDrawer">
    <div>
      <h2>Drawer Slot here</h2>
    </div>
  </BaseDrawer>
  <div class="side-drawer">
    <button class="drawer-button" :style="drawerButtonStyle" @click="toggleDrawer"><span>
        <span class="material-symbols-outlined">event_upcoming</span>
      </span></button>
  </div>
  <div class="desktop-view">
    <div class="info-column">
      <div class="card-container">
        <PatientCard></PatientCard>
      </div>
      <div class="card-container">
        <TumorBoardCard></TumorBoardCard>
      </div>
    </div>
    <div class="timeline-column">
      <div class="card-container">
        <ClinicalTimelineCard></ClinicalTimelineCard>
      </div>
    </div>
    <div class="diagnostics-column">
      <div class="diag-container">
        <DiagnosticsCard></DiagnosticsCard>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import NavBar from '@/ux3/components/NavBar.vue'
import BaseDrawer from '@/ux3/components/BaseDrawer/BaseDrawer.vue'
import PatientCard from '@/ux3/cards/PatientCard/PatientCard.vue'
import ClinicalTimelineCard from '@/ux3/cards/ClinicalTimelineCard/ClinicalTimelineCard.vue'
import DiagnosticsCard from '@/ux3/cards/DiagnosticsCard/DiagnosticsCard.vue'
import TumorBoardCard from '@/ux3/cards/TumorBoardCard/TumorBoardCard.vue'
//import GenomicsCard from '@/ux3/cards/GenomicsCard/GenomicsCard.vue'

const isDrawerOpen = ref(false)
const toggleDrawer = () => {
  isDrawerOpen.value = !isDrawerOpen.value
}

const closeDrawer = () => {
  isDrawerOpen.value = false
}

onMounted(() => {
  const body = document.getElementsByTagName('body')[0]
  body.style.overflowY = 'hidden'
  body.style.fontFamily = 'Roboto'
  const app = document.getElementById('app')
  app.style.position = 'static'  // fix body margin problem
})
</script>

<style lang="scss" scoped>
.desktop-view {
  display: flex;
  margin-left: 4rem;
  width: calc(100% - 4rem);
  height: calc(100% - 70px);
}

.info-column {
  width: 20%;
  display: flex;
  flex-direction: column;
  height: calc(100% - 70px);
}

.timeline-column {
  width: 55%;
  height: calc(100% - 70px);
}

.diagnostics-column {
  width: 25%;
  background-color: var(--White);
  margin-left: 1rem;
  height: calc(100vh - 70px);
}

.card-container {
  padding: 1rem;
}

.side-drawer {
  background-color: var(--White);
  border-style: none;
  box-shadow: 0 0 3px #b9b9b9;
  width: 2.5rem;
  cursor: pointer;
  font: inherit;
  position: absolute;
  top: 71px;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &>span {
    display: block;
    margin: 0 auto;
    padding-top: 3rem;
  }
}

.drawer-button {
  outline: none;
  border: none;
  background-color: transparent;
  margin-top: 1rem;
  transition: all linear 200ms;
  cursor: pointer;
  color: var(--Grey600);

  &:hover {
    color: var(--Primary);
  }
}
</style>

<style type="scss">
.timeline-column .base-card {
  height: calc(96vh - 70px);
}

.info-column .base-card {
  height: calc(50vh - 70px);
}
</style>
