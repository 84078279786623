<template>
  <div>
    <BaseCard :card-name="props.cardName">
      <template v-slot:icon>
        <span @click.prevent="onExpand" role="button" class="material-symbols-outlined">fullscreen</span>
      </template>
      <div class="container" :style="containerStyle" ref="toolContainerRef">
        <base-spinner class="base-spinner" v-if="loadingStatus === 'loading' && (patientId || !mustSelectPatient)">
        </base-spinner>
        <p v-if="mustSelectPatient && !patientId">{{ tr('No patient is currently selected.') }}</p>
        <p v-else-if="loadingStatus === 'timeout error' || loadingStatus === 'tool error'">{{ tr(loadingStatus) }}</p>
        <iframe v-else ref="iframe" :style="iframeStyle"></iframe>
      </div>
    </BaseCard>
  </div>
</template>

<script setup>
import { computed, defineProps, inject, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import BaseCard from '../BaseCard/BaseCard.vue'
import BaseSpinner from '@/components/ui/icons/BaseSpinner.vue'
import { clearIframe, loadTool } from '@/components/tools/BlackBoxSSRTool/loadTool.js'

const props = defineProps({
  cardName: String,
  payload: {
    type: Object,
    required: true,
  },
})

// const featureToggles = computed(() => store.getters.featureToggles)
const loadingStatus = ref('loading')
const store = useStore()
const tr = inject('tr')

const iframe = ref(null)

const containerStyle = computed(() => ({
  display: loadingStatus.value === 'loading' ? 'flex' : null,
}))
const iframeStyle = computed(() => ({
  display: loadingStatus.value === 'loading' ? 'none' : null,
}))
// const currentThemeName = computed(() => store.getters.currentThemeName)
const language = computed(() => store.getters.profile.language)
// const location = computed(() => featureToggles.value.providenceBuild ? 'providence' : 'default')  // TODO
const location = ref('providence')

const patientId = ref('0x444531')  // TODO
const patientBirthdate = ref('2012-01-02') // TODO
const patientFamilyName = ref('Asap') // TODO
const patientGivenName = ref('Janet') // TODO
const mustSelectPatient = ref(false)

const fetchMethod = (payload) => payload.url.method

const fetchUrl = (payload) => {
  const baseUrl = payload.url.baseUrl
  const queryParams = []
  const queryKeys = Object.keys(payload.url.queryParams)
  let addedToolId = false
  queryKeys.forEach((key) => {
    const info = payload.url.queryParams[key]
    if (key === 'language') {
      queryParams.push(`${info.key}=${encodeURIComponent(language.value)}`)
    } else if (key === 'currentThemeName') {
      // queryParams.push(`${info.key}=${encodeURIComponent(currentThemeName.value)}`)  // TODO
    } else if (key === 'birthdate') {
      queryParams.push(`${info.key}=${encodeURIComponent(patientBirthdate.value)}`)
    } else if (key === 'family') {
      queryParams.push(`${info.key}=${encodeURIComponent(patientFamilyName.value)}`)
    } else if (key === 'given') {
      queryParams.push(`${info.key}=${encodeURIComponent(patientGivenName.value)}`)
    } else if (key === 'patientId') {
      if (info.required && !patientId.value) {
        return // Do not call tool until a patient is selected.
      }
      if (patientId.value) {
        let idValue = patientId.value
        if (idValue.includes(',')) {
          // This patient has multiple MRNs. Send only the first one.
          const parts = idValue.split(',')
          idValue = parts[0]
        }
        queryParams.push(`${info.key}=${encodeURIComponent(idValue)}`)
      }
    } else if (key === 'patientIds') {
      if (info.required && !patientId.value) {
        return // Do not call tool until a patient is selected.
      }
      if (patientId.value) {
        let idValues = [patientId.value]
        if (patientId.value.includes(',')) {
          // This patient has multiple MRNs. Send all of them.
          // This splits up the CSV into parts and renders the URL as:
          // patient_identifier=mrn1&patient_identifier=mrn2
          idValues = patientId.value.split(',')
        }
        idValues.forEach((val) => {
          queryParams.push(`${info.key}=${encodeURIComponent(val)}`)
        })
      }
    } else if (key === 'toolId') {
      queryParams.push(`${info.key}=${encodeURIComponent(props.toolId)}`)
      addedToolId = true
    } else if (key === 'location') {
      queryParams.push(`${info.key}=${encodeURIComponent(location.value)}`)
    } else {
      const value = payload.data[key]
      if (!info.required && !value) {
        return // Do not add optional undefined parameter.
      }
      queryParams.push(`${info.key}=${encodeURIComponent(value)}`)
    }
  })
  // Always add toolId parameter to prevent browser caching from getting an old (cached) tool response.
  if (!addedToolId) {
    queryParams.push(`toolId=${encodeURIComponent(props.toolId)}`)
  }
  return `${baseUrl}?${queryParams.join('&')}`
}

const forceToolReload = (payload) => {
  const url = fetchUrl(payload)
  if (!url) { return }
  loadingStatus.value = 'loading'
  clearIframe(iframe)
  if (mustSelectPatient.value && !(patientId.value)) { return }  // don't attempt to load tool if no patient but must have one.
  const method = fetchMethod(payload)
  const body = payload.data?.body
  loadTool(method, url, body, iframe, loadingStatus, props.toolId)
}

const toolContainerRef = ref(null)
const onExpand = () => {
  const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement
  if (!fullscreenElement) {
    if (toolContainerRef.value?.requestFullscreen) {
      toolContainerRef.value.requestFullscreen()
    } else if (toolContainerRef.value?.webkitRequestFullscreen) {
      toolContainerRef.value.webkitRequestFullscreen()
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
  }
}

onMounted(() => {
  forceToolReload(props.payload)
  const tool = store.getters.toolById(props.toolId)
  if (tool) {
    tool.onReloadTool = forceToolReload
  }
})

</script>

<style lang="scss" scoped>
.expand-icon {
  width: 1rem;
}

.container {
  position: static;
  width: 100%;
  height: calc(100% - 60.5px);
  border-radius: 0 0 16px 16px;
  background-color: var(--toolBackgroundColor);


  iframe {
    border-style: none;
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
}

.base-spinner {
  margin: auto;
  width: 100px;
  height: 100px;
}

p {
  margin: auto;
  padding-top: 20px;
  color: red;
  font-size: 1rem;
}
</style>
