<template>
  <div v-if="featureToggles.providenceBuild">
    <img
      class="logo-loader"
      src="/assets/images/providence_color_spinner.webp"
    >
  </div>
  <svg
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    v-else
  >
    <circle
      cx="50"
      cy="50"
      r="45"
      :stroke="spinnerStrokeColor"
    />
  </svg>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js'

export default {
  setup() {
    const store = useStore()

    const featureToggles = computed(() => store.getters.featureToggles)

    const spinnerStrokeColor = computed(() => {
      return themeColors[store.getters.currentThemeName].spinnerStrokeColor
    })

    return {
      featureToggles,
      spinnerStrokeColor,
    }
  },
}
</script>

<style lang="scss" scoped>
// https://glennmccomb.com/articles/building-a-pure-css-animated-svg-spinner/
svg {
  animation: 2s linear infinite svg-animation;
  max-width: 100px;
}

@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

circle {
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 10px;
  transform-origin: 50% 50%;
}

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}

.logo-loader {
  display: block;
  margin: 0 auto;
  width: 35%;
  animation: spin-logo 2s linear infinite;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@keyframes spin-logo {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
