<template>
  <div>
    <BlackBoxToolCard :card-name="tr('Clinical Timeline')" :payload="payload"></BlackBoxToolCard>
  </div>
</template>

<script setup>
// import { inject } from 'vue'
import BlackBoxToolCard from '../BlackBoxToolCard/BlackBoxToolCard.vue'
import getEnv from '@/utils/env'
const tr = (x) => x // inject('tr')

const baseUrl = getEnv('VUE_APP_CLINICAL_TIMELINE_BASE_URL') || 'https://oda-aks-dev-ux2.westus2.cloudapp.azure.com/timeline'

const payload = {
  data: {
    neutrophils: '',
    platelet: '',
    wbc: '',
  },
  url: {
    baseUrl,
    method: 'GET',
    queryParams: {
      birthdate: { key: 'birthdate', required: true },
      currentThemeName: { key: 'ux2_theme', required: true },
      family: { key: 'family', required: true },
      given: { key: 'given', required: true },
      language: { key: 'language', required: true },
      neutrophils: { key: 'neutrophils', required: true },
      patientId: { key: 'MRN', required: true },
      platelet: { key: 'platelet', required: true },
      toolId: { key: 'toolId', required: true },
      wbc: { key: 'wbc', required: true },
    },
  },
}

</script>

<style lang="scss" scoped></style>
